/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import { Message, Container } from 'semantic-ui-react';

// Array of loading messages. First entry is there twice due to how 'Math.Random' works in javascript.
const loadingMessages = [
  "Getting you a bunch of supercomputers, please wait!",
  "Getting you a bunch of supercomputers, please wait!",
  "Loading the power of AI, hang tight!",
  "Summoning the digital spirits...",
  "Loading the power of the cloud...",
  "Unzipping the internet…",
  "Teleporting you to the digital world…",
  "Deploying some containers…",
  "Warming up the servers…",
  "Dialing up the modem…",
  "Training the neural network…",
  "Hang on, we're finding the end of Pi…",
  "Brewing the perfect code…",
  "Loading… and appreciating your patience.",
  "Loading… still faster than elevator small talk.",
  "Remember, you are changing our future!",
  "Research shows that waiting is good for your health.",
  "Researching a better loading screen…",
];

const SvgIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} />
);

const loadingIcon = () => (
  <SvgIcon viewBox="0 0 100 100" style={{ width: '100px', height: '100px' }}>
    <path fill="#000054" d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
      c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
      <animateTransform 
         attributeName="transform" 
         attributeType="XML" 
         type="rotate"
         dur="2s" 
         from="0 50 50"
         to="360 50 50" 
         repeatCount="indefinite" />
    </path>
    <path fill="#000054" d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
      c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
      <animateTransform 
         attributeName="transform" 
         attributeType="XML" 
         type="rotate"
         dur="1s" 
         from="0 50 50"
         to="-360 50 50" 
         repeatCount="indefinite" />
    </path>
    <path fill="#E61E2A" d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
      L82,35.7z">
      <animateTransform 
         attributeName="transform" 
         attributeType="XML" 
         type="rotate"
         dur="2s" 
         from="0 50 50"
         to="360 50 50" 
         repeatCount="indefinite" />
    </path>
  </SvgIcon>
);

// Render the AppContainer component which will then ask plugins to provide the App component
function renderAppContainer(AppContainer, appContext) {
  ReactDOM.render(
    <Provider {...appContext}>
      <BrowserRouter>
        <AppContainer />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root'),
  );
}

// Render a progress message
function renderProgress(targetDocument = document, message) {
  // If no message is provided, select a random message from the array
  const displayMessage = message ?? loadingMessages[Math.floor(Math.random() * loadingMessages.length)];

  const progressContent = (
    <div style={{ marginTop: '20px', textAlign: 'center', animation: 'fadeIn 1.0s ease-in' }}>
      {loadingIcon()}
      <div style={{ marginTop: '20px', animation: 'fadeIn 0.5s ease-in 0.3s both' }}>
        {displayMessage}
      </div>
    </div>
  );

  ReactDOM.render(
    <div style={{ backgroundColor: 'white', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {progressContent}
    </div>,
    targetDocument.getElementById('root')
  );
}

// Render an error message
function renderError(err) {
  const error = _.get(err, 'message', 'Unknown error');
  ReactDOM.render(
    <Container text className="pt4">
      <Message negative>
        <Message.Header>An error occured while loading the site</Message.Header>
        <p>{error}</p>
        <p>See if refreshing the browser will resolve your issue</p>
        <p>Please also check there is nothing after the slash in the web address</p>
        <p>The address should be: https://race.rmit.edu.au/ with nothing after the '/'</p>
      </Message>
    </Container>,
    document.getElementById('root'),
  );
}

export { renderAppContainer, renderProgress, renderError };
