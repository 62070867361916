/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */
import _ from 'lodash';
import React from 'react';
import { decorate, computed, action, runInAction, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Table } from 'semantic-ui-react';

import { displayError } from '@amzn/base-ui/dist/helpers/notification';

const openWindowWithAnchor = (url) => {
  const anchorElement = document.createElement('a');
  anchorElement.href = url;
  anchorElement.target = '_blank';
  anchorElement.rel = 'noopener noreferrer';
  document.body.appendChild(anchorElement);
  anchorElement.click();
  document.body.removeChild(anchorElement);
};

class ScEnvironmentDcvConnectionRow extends React.Component {
  constructor(props) {
    super(props);
    runInAction(() => {
      this.generatingBrowserToken = false;
      this.generatingClientToken = false;
    });
  }

  get environment() {
    return this.props.scEnvironment;
  }

  get envsStore() {
    return this.props.scEnvironmentsStore;
  }

  getConnectionStore() {
    return this.envsStore.getScEnvConnectionStore(this.environment.id);
  }

  // Returns only the connections that scheme = 'dcv'
  // [ {id, name: <string>(optional), instanceId: <string>, scheme: 'dcv'}, ... ]
  get connections() {
    const connections = this.environment.getConnections(item => item.scheme === 'dcv');

    return connections;
  }

  get connection() {
    const id = this.connectionId;
    const connections = this.connections;

    return _.find(connections, ['id', id]) || {};
  }

  get connectionId() {
    return this.props.connectionId;
  }

  handleConnectBrowser = id => this.handleConnect(id, false);

  handleConnectClient = id => this.handleConnect(id, true);

  handleConnect = (id, useClientProtocol) =>
    action(async () => {
      const store = this.getConnectionStore();
      const connections = this.environment.connections;
      const connectInfo = _.find(connections, ['id', id]) || {};
      let url = connectInfo.url;
      let newWindow;
  
      runInAction(() => {
        this.processingId = id;
        if (useClientProtocol) {
          this.generatingClientToken = true;
        } else {
          this.generatingBrowserToken = true;
        }
      });
  
      try {
        if (!useClientProtocol) {
          newWindow = window.open(`${window.location.origin}/transit.html`, '_blank');
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            throw new Error('Popup was blocked. Please allow popups for this site to use the DCV browser connection.');
          }
        }
  
        // Handle the async URL generation
        if (!url) {
          const urlObj = await store.createConnectionUrl(id);
          url = urlObj.url;
        }
  
        if (url) {
          if (useClientProtocol) {
            url = url.replace('https://', 'dcv://');
            window.location.href = url;
          } else if (newWindow) {
            newWindow.location = url;
          }
        }
      } catch (error) {
        if (newWindow) {
          newWindow.close();
        }
        displayError(error);
      } finally {
        runInAction(() => {
          this.processingId = '';
          this.generatingBrowserToken = false;
          this.generatingClientToken = false;
        });
      }
    });

  render() {
    const processingId = this.processingId;
    const connections = this.connections;
    const isDisabled = id => processingId !== id && !_.isEmpty(processingId);
    return (
      <>
        {_.map(connections, item => (
          <>
            <Table.Row key={item.id}>
              <Table.Cell className="clearfix">
                <Button
                  floated="right"
                  size="mini"
                  primary
                  disabled={isDisabled(item.id)}
                  loading={this.generatingClientToken}
                  onClick={this.handleConnectClient(item.id)}
                  data-testid="connect-using-dcv-button"
                >
                  Open DCV (Client)
                </Button>
                <Button
                  floated="right"
                  size="mini"
                  primary
                  disabled={isDisabled(item.id)}
                  loading={this.generatingBrowserToken}
                  onClick={this.handleConnectBrowser(item.id)}
                  data-testid="connect-using-dcv-button"
                >
                  Open DCV (Browser)
                </Button>
                <div className="mt1">{item.name || 'Connect'}</div>
              </Table.Cell>
            </Table.Row>
          </>
        ))}
      </>
    );
  }
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(ScEnvironmentDcvConnectionRow, {
  envsStore: computed,
  environment: computed,
  connections: computed,
  connection: computed,
  connectionId: computed,
  handleConnect: action,
  generatingBrowserToken: observable,
  generatingClientToken: observable,
});

export default inject('scEnvironmentsStore')(withRouter(observer(ScEnvironmentDcvConnectionRow)));
