import _ from 'lodash';
import React from 'react';
import { observer } from 'mobx-react';
import c from 'classnames';

const Component = observer(({ date, className, emptyMessage = 'Not Provided' }) => {
  if (_.isEmpty(date)) return <span className={c(className)}>{emptyMessage}</span>;

  const firstDate = new Date(date);
  const secondDate = new Date();

  const diffMilliseconds = Math.abs(firstDate - secondDate);
  const diffSeconds = Math.round(diffMilliseconds / 1000);
  const diffMinutes = Math.round(diffMilliseconds / (1000 * 60));
  const diffHours = Math.round(diffMilliseconds / (1000 * 60 * 60));
  const diffDays = Math.round(diffMilliseconds / (1000 * 60 * 60 * 24));

  let message = '';
  if (firstDate > secondDate) {
    if (diffDays >= 1) {
      message = `Expires in ${diffDays} day${diffDays === 1 ? '' : 's'}`;
    } else if (diffHours >= 1) {
      message = `Expires in ${diffHours} hour${diffHours === 1 ? '' : 's'}`;
    } else if (diffMinutes >= 1) {
      message = `Expires in ${diffMinutes} minute${diffMinutes === 1 ? '' : 's'}`;
    } else {
      message = `Expires in ${diffSeconds} second${diffSeconds === 1 ? '' : 's'}`;
    }
  } else {
    message = 'Expired';
  }

  const isExpired = firstDate < secondDate;
  const textColor = (diffDays <= 7 || isExpired) ? 'color: red !important;' : 'color: inherit;';

  return (
    <span className={c(className)} style={{cssText: textColor}}>
      {message}
    </span>
  );
});

export default Component;